<template>
    <div class="load-root">
        <div class="loadding" v-if="loadding">
            <a-spin :spinning="loadding" tip="加载中"></a-spin>
        </div>
        <div v-else>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loadding',
        props: {
            loadding: {
                type: Boolean,
                default: false,
            }
        },
        data(){
            return {

            }
        },
        methods: {

        },
    }
</script>

<style>
    .loadding{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: #fff;
    }
    .load-root{
        height: 100%;
        position: relative;        
    }
</style>
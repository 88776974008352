<template>
    <div class="ui-switch">
        <el-switch v-bind="$attrs" v-model="modelValue" v-on="$listeners"></el-switch>
    </div>
</template>

<script>
    export default {
        name: 'u-Switch',
        props: [
            'value',
            'custom'
        ],
        data(){
            return {

            }
        },
        computed: {
            modelValue: {
                get(){
                    const custom = this.$props.custom
                    if(custom){
                        let keys = Object.keys(custom)
                        for(let i=0; i < keys.length; i++){
                            if(custom[keys[i]] == this.$props.value){
                                return true
                            }
                        }
                        return false
                    }
                    return this.$props.value
                },
                set(val){
                    const custom = this.$props.custom
                    if(custom){
                        this.$emit('input', custom[`${val}`])
                    } else {
                        this.$emit('input', val)
                    }
                }
            }
        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
    .ui-switch{
        display: flex;
        align-items: center;
        height: 32px;
    }
</style>

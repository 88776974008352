const  router = [
	{
		path: "/application/layout",
		component: () => import('../../../pages/application/Layout.vue'),
		meta: {title: '应用'},
		name: 'application_layout',
		children:[
			{
				path: "/application",
				component: () => import('../../../pages/application/Index.vue'),
				meta: {title: '上架中'},
				name: 'application_index',
			},
			{
				path: "/application/developing",
				component: () => import('../../../pages/application/Developing.vue'),
				meta: {title: '开发中'},
				name: 'application_developing',
			},
			{
				path: "/application/activationCode",
				component: () => import('../../../pages/application/activationCode.vue'),
				meta: {title: '激活码'},
				name: 'application_activation_code',
			},
			{
				path: "/application/edit",
				component: () => import('../../../pages/application/Edit.vue'),
				meta: {title: '编辑应用'},
				name: 'application_index_edit',
			}
		]
		
	}
	
];

export default router
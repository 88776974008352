export default {
  state: {
	  test:0,
    app_id:1
  },
  mutations: { 
	  setTest(state,param){
		  state.test = param
	  }
  }
}
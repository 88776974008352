

export default [
	{
		path: "/agent/layout",
		component: () => import('@/pages/agent/Layout.vue'),
		meta: {
			title: '代理'
		},
		name: 'agent_layout',
		children:[
			{
				path: "/agent",
				component: () => import('@/pages/agent/Settings/index.vue'),
				meta: {title: '代理后台设置'},
				name: 'agent_index'
			},
			{
				path: "/agent/type",
				component: () => import('@/pages/agent/Type/index.vue'),
				meta: {title: '代理后台设置'},
				name: 'agent_type'
			},
			{
				path: "/agent/manage",
				component: () => import('@/pages/agent/Manage/index.vue'),
				meta: {title: '代理后台设置'},
				name: 'agent_manage'
			},
			{
				path: "/agent/withdrawal",
				component: () => import('@/pages/agent/Withdrawal/index.vue'),
				meta: {title: '代理后台设置'},
				name: 'agent_withdrawal'
			},
        ]
    }
]
<template>
    <el-dialog
    :visible.sync="dialogVisible"
    v-bind="$attrs"
    :before-close="onHide"
    :close-on-click-modal="false"
    custom-class="jg-file-viewer" 
    >
        <Form
        ref="form"
        v-bind="$props"
        >
        </Form>
        
        <span slot="footer" class="dialog-footer">
            <el-button @click="onHide">取 消</el-button>
            <el-button type="primary" @click="onConfirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'DialogForm',
    props: {
        formSchema: {
            type: Array,
            default: () => []
        },
        labelWidth: {
            type: Number,
            default: 85,
        },
    },
    data() {
        return {
            dialogVisible: false,
        }
    },
    methods: {
        onShow(state=false, data){
            this.dialogVisible = true
            if(state){
                setTimeout(() => {
                    this.$refs.form.setData(data)
                });
            }
        },
        onHide(){
            this.dialogVisible = false
            setTimeout(() => {
                this.$refs.form.resetFields()
            }, 100);
        },
        onConfirm(){
            this.$refs.form.validate((valid, data) => {
                if(valid){
                    this.$emit('confirm', JSON.parse(JSON.stringify(data)))
                    this.onHide()
                }
            })
        }
    }
};
</script>

<style scoped lang="less">
    :deep(.el-dialog__body){
        
    }
    :deep(.el-dialog__footer){
        border-top: 1px solid #e4e8f0;
        padding-bottom: 15px;
        padding-top: 15px;
    }
    :deep(.el-dialog .el-dialog__header){
        border-bottom: 1px solid #e4e8f0;
        padding-top: 15px;
        padding-bottom: 15px;
        .el-dialog__title{
            font-size: 15px;
            font-weight: bold;
        }
        .el-dialog__headerbtn{
            top: 15px
        }
    }
</style>
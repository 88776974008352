<template>
    <div class="global-form-container">
        <ul class="clearfix">
            <template v-for="item in formSchema">
                <li :style="item?item.style : {}" v-show="onShow(item)" v-if="ifShow(item)">
                    <div class="item-flex" :style="{width: ratioStyle(item)}">
                        <div class="label" :style="{'--larbelWidth': `${labelWidth}px`}" v-if="item.label">
                            <em v-if="dynamicRules(item)">*</em>{{ item.label }}：
                        </div>
                        <component
                        :is="item.component"
                        v-bind="item.componentProps"
                        v-model="formdata[item.field]"
                        >
                        </component>
                    </div>
                </li>
            </template>
            <template>
                <slot></slot>
            </template>
        </ul>
    </div>
</template>
<script>
import Upload from './Upload'
import Input from './Input'
import Select from './Select'
import ApiSelect from './ApiSelect.vue'
import Radio from './Radio'
import InputNumber from './InputNumber'
export default {
    name: 'Form',
    components: {
        Upload,
        Input,
        Select,
        Radio,
        InputNumber,
        ApiSelect,
    },
    props: {
        formSchema: {
            type: Array,
            default: []
        },
        labelWidth: {
            type: Number,
            default: 85,
        }
    },
    data(){
        return {
            formdata: {

            }
        }
    },
    created(){
        this.init()
    },
    mounted(){
        
    },
    methods: {
        ratioStyle(item){
            if(Reflect.has(item, 'ratio')){
                return `${this.labelWidth + item.ratio}px`
            } else {
                return null
            }
        },
        /** 动态验证 */
        dynamicRules(item){
            if(Reflect.has(item, 'required')){
                return item.required
            } else {
                if(Reflect.has(item, 'dynamicRules')){
                    const { required } = item.dynamicRules(this.formdata)
                    return required
                }
            }
        },
        /** 动态显示 */
        ifShow(item){
            if(Reflect.has(item, 'ifShow')){
                let result = item.ifShow(this.formdata)
                if (result){
                    if(!Reflect.has(this.formdata, item.field)){
                        this.$set(this.formdata, item.field, this.decorate(item.component))
                    }
                } else {
                    if(Reflect.has(this.formdata, item.field)) {
                        this.$delete(this.formdata, item.field)
                    }
                }
                return result
            }
            return true
        },
        /** 控制显示 */
        onShow(item){
            if(Reflect.has(item, 'show')){
                return item.show
            }
            return true
        },
        decorate(key){
            switch (key){
                case 'Cascader':
                    return []
                case 'Switch':
                    return false
                default:
                    return ''
            }
        },
        init(){
            this.$props.formSchema.forEach(item=>{
                const { defaultValue } = item
                this.$set(this.formdata, item.field, defaultValue ?? this.decorate(item.component))
            })
        },
        setData(data){
            Object.keys(this.formdata).forEach(key=>{
                if(Reflect.has(data, key)){
                    this.formdata[key] = data[key]
                }
            })
        },
        resetFields(){
            this.init()
        },
        validate(resolve){
            try {
                let isValid = true
                this.formSchema.forEach(item=>{
                    if(Reflect.has(item, 'required')){
                        if(item.required){
                            if(!this.formdata[item.field]){
                                isValid = false
                                throw new Error(`${item.label}不能为空`)
                            }
                        }
                    } else if(Reflect.has(item, 'dynamicRules')){
                        const {required, validator} = item.dynamicRules(this.formdata)
                        if(required){
                            if(!this.formdata[item.field]){
                                isValid = false
                                throw new Error(`${item.label}不能为空`)
                            }
                            if(typeof validator === 'function'){
                                const { msg } = validator({...item, value: this.formdata[item.field]})
                                if(typeof result == 'string'){
                                    throw new Error(msg)
                                }
                            }
                        }
                    }
                    
                })
                if(isValid){
                    resolve(isValid, this.formdata)
                }else{
                    resolve(isValid)
                }
            } catch (error) {
                // console.log(1, error);
                this.$message.error(error.message)
                resolve(false)
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .global-form-container{
        ul{
            margin-bottom: 0;
            li{
                width: 50%;
                float: left;
                margin: 8px 0;
                list-style: none;
      
                .item-flex{
                    display: flex;
                    flex-grow: 1;
                    align-items: flex-start;
                    .label{
                        flex-shrink: 0;
                        line-height: 32px;
                        width: var(--larbelWidth);
                        text-align: right;
                        color: #666666d8;
                        em{
                            color: red;
                            font-weight: bold;
                            margin-right: 4px;
                            position: relative;
                            top: 2px;
                        }
                        & + *{
                            flex-grow: 1;
                        }
                    }
                }
            }
        }
    }
</style>
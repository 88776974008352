<template>
    <el-radio-group v-model="radio">
        <el-radio class="item-radio" v-for="item in options" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
    </el-radio-group>
</template>
  
<script>
export default {
    inheritAttrs: false,
    name: 'Radio',
    props: ['value', 'options'],
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    computed: {
        radio: {
            get(){
                return this.$props.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .el-radio.item-radio{
        float: left;
        margin-right: 14px !important;
    }
    .el-radio-group{
        display: flex;
        align-items: center;
    }
</style>
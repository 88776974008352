<template>
    <el-select v-model="modelValue" multiple filterable allow-create default-first-option placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >
        </el-option>
    </el-select>
</template>
  
<script>
export default {
    name: 'ApiSelect',
    props: ['value', 'options'],
    data() {
        return {
            
        }
    },
    computed: {
        modelValue: {
            get(){
                return this.$props.value
            },
            set(v){
                this.$emit('input', v)
            }
        }
    }
}
</script>
import axios from 'axios'
import qs from 'qs'
import { message } from 'ant-design-vue'
import router from '../router/router.js';
axios.defaults.timeout = 200000;

axios.defaults.baseURL = window.global_config.BASE_URL+ "/";
// axios.defaults.baseURL = process.env.VUE_APP_BASE_API + "/";
//返回状态判断
axios.interceptors.response.use((res) => {
    if (res.status == 200) {
        return res;
    } else if (res.status == 401) {
        message.error('请先登录！');
        localStorage.removeItem('admin-token');
        router.push('/');
        return Promise.reject(response.data.msg || 'error');
    } else {
        return Promise.reject(res);
    }
}, (error) => {

    //console.log(error);
    return Promise.reject(error);
});
axios.interceptors.request.use((config) => {
    if (config.method === 'post') {
        if (config.headers['Content-Type'] !== 'multipart/form-data;charset=UTF-8') {
            config.data = config.data || {};
            config.data.token = localStorage.getItem('admin-token');
            config.data = qs.stringify(config.data);
        } else { //上传文件逻辑
            config.data = config.data;
            config.data.append('type', 'admin'); //来源哪里的上传
            config.data.append('token', localStorage.getItem('admin-token'));
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});


export function upload(file) {
    var datas = new FormData();
    var configs = {
        headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' }
    };
    datas.append('file', file);
    return axios.post('api/common/upload', datas, configs)
        .then(response => {
            switch (response.data.code) {
                case 1:
                    return response.data;
                default:
                    return Promise.reject(response.data.msg || 'error');
            }
        })
        .catch((msg) => {
            return Promise.reject(msg || 'error')
        });
}


export function api(url, params) {
    var configs = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
    };
    return axios.post(url, params, configs)
        .then(response => {
            switch (response.data.code) {
                case 401:
                    message.error(response.data.msg);
                    localStorage.removeItem('admin-token');
                    router.push('/');
                    return Promise.reject(response.data.msg || 'error');
                case 1:
                    return response.data;
                case 0:  //400 为提示类型错误
                    message.error(response.data.msg);
                    return Promise.reject(response.data.msg || 'error');
                case 500:
                    return Promise.reject('网络繁忙');
                    break;
                default:
                    return Promise.reject(response.data.msg || 'error');
            }
        })
        .catch((msg) => {
            if (msg.response.status == 401) {
                message.error(msg.response.data.msg);
                localStorage.removeItem('admin-token');
                router.push('/');
                return Promise.reject(response.data.msg || 'error');
            }
            return Promise.reject(msg || 'error')
        });

}

const  router = [
	{
		path: "/template",
		component: () => import('../../../pages/template/index.vue'),
		meta: {title: '模版'},
		name: 'template_index',
	},
	{
		path: "/template/mall",
		component: () => import('../../../pages/template/mall.vue'),
		meta: {title: '会员制零售商城模板'},
		name: 'template_mall',
	},
	{
		path: "/template/motherhood",
		component: () => import('../../../pages/template/motherhood.vue'),
		meta: {title: '母婴门店模板'},
		name: 'template_motherhood',
	},
	{
		path: "/template/beautiful",
		component: () => import('../../../pages/template/beautiful.vue'),
		meta: {title: '美业门店模板'},
		name: 'template_beautiful',
	}
	,
	{
		path: "/template/yoga",
		component: () => import('../../../pages/template/yoga.vue'),
		meta: {title: '瑜伽健身模板'},
		name: 'template_yoga',
	},
	{
		path: "/template/pets",
		component: () => import('../../../pages/template/pets.vue'),
		meta: {title: '宠物门店模板'},
		name: 'template_pets',
	},
	{
		path: "/template/housekeeping",
		component: () => import('../../../pages/template/housekeeping.vue'),
		meta: {title: '上门家政模板'},
		name: 'template_housekeeping',
	},
	{
		path: "/template/daojia",
		component: () => import('../../../pages/template/daojia.vue'),
		meta: {title: '到家模板'},
		name: 'template_daojia',
	},
	{
		path: "/template/photography",
		component: () => import('../../../pages/template/photography.vue'),
		meta: {title: '摄影模版'},
		name: 'template_photography',
	},
	{
		path: "/template/spa",
		component: () => import('../../../pages/template/spa.vue'),
		meta: {title: 'spa按摩模版'},
		name: 'template_spa',
	},
	{
		path: "/template/pifa",
		component: () => import('../../../pages/template/pifa.vue'),
		meta: {title: '批发模版'},
		name: 'template_pifa',
	},
];

export default router
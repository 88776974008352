const  router = [
	{
		path: "/shop/layout",
		component: () => import('../../../pages/shop/Layout.vue'),
		meta: {title: '小程序'},
		name: 'shop_layout',
		children:[
			{
				path: "/shop",
				component: () => import('../../../pages/shop/Index.vue'),
				meta: {title: '使用中'},
				name: 'shop_online',
			},
			{
				path: "/shop/notice",
				component: () => import('../../../pages/shop/Notice.vue'),
				meta: {title: '即将到期'},
				name: 'shop_notice',
			},
			{
				path: "/shop/test",
				component: () => import('../../../pages/shop/Test.vue'),
				meta: {title: '试用中'},
				name: 'shop_test',
			},
			{
				path: "/shop/expire",
				component: () => import('../../../pages/shop/Expire.vue'),
				meta: {title: '已失效店铺'},
				name: 'shop_expire',
			},
			{
				path: "/shop/kefu",
				component: () => import('../../../pages/shop/Kefu.vue'),
				meta: {title: '客服'},
				name: 'shop_kefu',
			},
			{
				path: "/shop/free",
				component: () => import('../../../pages/shop/free.vue'),
				meta: {title: '免认证'},
				name: 'shop_free',
			},
		]
		
	}
];

export default router
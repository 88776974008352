const  router = [
	{
		path: "/setting/layout",
		component: () => import('../../../pages/setting/Layout.vue'),
		meta: {title: '设置'},
		name: 'setting_layout',
		children:[
			{
				path: "/setting",
				component: () => import('../../../pages/setting/Index.vue'),
				meta: {title: '站点基本设置'},
				name: 'setting_index'
			},
			{
				path: "/setting/oss",
				component: () => import('../../../pages/setting/Oss.vue'),
				meta: {title: '文件存储设置'},
				name: 'setting_oss'
			},
			{
				path: "/setting/open",
				component: () => import('../../../pages/setting/Open.vue'),
				meta: {title: '开放平台设置'},
				name: 'setting_open'
			},
			{
				path: "/setting/sms",
				component: () => import('../../../pages/setting/Sms.vue'),
				meta: {title: '短信发送设置'},
				name: 'setting_sms'
			},
			{
				path: "/setting/sms1",
				component: () => import('../../../pages/setting/Sms1.vue'),
				meta: {title: '短信签名管理'},
				name: 'setting1_sms'
			},
			{
				path: "/setting/payment",
				component: () => import('../../../pages/setting/Payment.vue'),
				meta: {title: '支付设置'},
				name: 'setting_payment'
			},
			{
				path: "/setting/package",
				component: () => import('../../../pages/setting/package.vue'),
				meta: {title: '充值套餐设置'},
				name: 'setting_package'
			},
			{
				path: "/setting/code",
				component: () => import('../../../pages/setting/code.vue'),
				meta: {title: '激活码'},
				name: 'setting_code'
			}
		]
	}
];

export default router
<template>
    <div class="sun-id">{{ value }}</div>
</template>


<script>
    export default {
        name: 'Id',
        props: ['value'],
        data(){
            return {

            }
        },
    }
</script>

<style lang="less" scoped>
    .sun-id{
        font-size: 14px;
        color: #333;
        border-bottom: 1px solid #e7e7e7;
        text-align: center;
    }
</style>
import Vue from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import  store from './common/store/index.js'

import 'ant-design-vue/dist/antd.less'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI, {size: 'small'});


import  './assets/css/font.css'
import './assets/css/common.css'

Vue.use(Antd)

import mzUI from './components/mzUI/index'
Vue.use(mzUI)

Vue.config.productionTip = false

import router from './common/router/router';
import * as http  from './common/request/request.js';
Vue.prototype.$http = http;
import {common} from './common/mixin/common.js';
Vue.mixin(common);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')